<template>
  <div>
    <b-tooltip target="payout-info">{{$t('payoutInfo')}}</b-tooltip>
    <b-card 
      class="card-container"
      body-class="border-card-info"
      header-class="border-header"
     >
    <template #header>
        <span class="title-text">{{$t('titlePayout')}} <b-icon icon="question-circle" id="payout-info"></b-icon></span> 
    </template>
    <div class="mt-2">
        <div class="card-subtitle-preference">
          <span class="w-100 d-block subtitle-current-email">
            {{ $t('currentPaypalEmail') }}
            <b-badge  pill :class="profile.paypal_info && profile.paypal_info.email_paypal ? 'verified-email' : 'not-verified-email'"> {{ profile.paypal_info && profile.paypal_info.email_paypal ? $t("creator.verified"): $t("creator.notVerified")}}</b-badge> 
          </span>
          <div>
            <span class="mr-05" v-if="profile.paypal_info && profile.paypal_info.email_paypal !== ''">{{ profile.paypal_info.email_paypal }} </span>
            <b-link class="link-update-paypal-info avenir-medium" @click="siderPayPal()">{{profile.paypal_info && profile.paypal_info.email_paypal  !== '' ? $t('updateEmailPaypal') : $t('addPaypalEmail')}}</b-link> 
          </div>
        </div>
    </div>

  </b-card>  

  <b-tooltip target="payoneer">{{$t('payoneerInfo')}}</b-tooltip>
    <b-card 
      class="card-container"
      body-class="border-card-info"
      header-class="border-header"
     >
    <template #header>
        <span class="title-text">{{$t('payoneerPayout')}} <b-icon icon="question-circle" id="payoneer"></b-icon></span> 
    </template>
    <div class="mt-2">
        <div class="card-subtitle-preference">
          <span class="w-100 d-block subtitle-current-email">
            {{ $t('currentPayoneerEmail') }}
            <b-badge  pill :class="profile.paypal_info && profile.paypal_info.email_payoneer ? 'verified-email' : 'not-verified-email'"> {{ profile.paypal_info && profile.paypal_info.email_payoneer ? $t("creator.verified"): $t("creator.notVerified")}}</b-badge> 
          </span>
          <div>
            <span class="mr-05" v-if="profile.paypal_info && profile.paypal_info.email_payoneer !== ''">{{ profile.paypal_info.email_payoneer }} </span>
            <b-link class="link-update-paypal-info avenir-medium" @click="siderPayoneer()">{{profile.paypal_info && profile.paypal_info.email_payoneer  !== '' ? $t('updateEmailPayoneer') : $t('addPayoneerEmail')}}</b-link> 
          </div>
        </div>
    </div>

  </b-card>

  <b-card>
    <validation-observer #default="{invalid}">
      <b-form>
        <b-row>
          <b-col sm="6">
            <b-form-group
              label-for="is_national"
              class="avenir-medium f-12"
              :description="$t('accountMx')"
            >
              <b-form-checkbox id="is_nacional" v-model="is_national">{{$t('accountNational')}}</b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('nameBeneficiary')"
              label-for="beneficiary_name"
            >
              <validation-provider 
                #default="{ errors }"
                :name="$t('nameBeneficiary')"
                :vid="$t('nameBeneficiary')"
                rules="required">
                <b-form-input 
                  id="beneficiary_name" 
                  v-model="beneficiary_name"
                  :placeholder="$t('nameBeneficiary')" 
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('lastNameBeneficiary')"
              label-for="beneficiary_last_name"
            >
              <validation-provider  
                #default="{ errors }"
                :name="$t('lastNameBeneficiary')"
                :vid="$t('lastNameBeneficiary')"
                rules="required">
                <b-form-input 
                  id="beneficiary_last_name" 
                  v-model="beneficiary_last_name"
                  :placeholder="$t('lastNameBeneficiary')" 
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('bank')"
              label-for="bank"
            >
              <validation-provider  
                #default="{ errors }"
                :name="$t('bank')"
                :vid="$t('bank')"
                rules="required">
                <b-form-input 
                  id="bank" 
                  v-model="bank" 
                  :placeholder="$t('bank')" 
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="getLabelClabe()"
              label-for="clabe"
            >
              <validation-provider 
                #default="{ errors }"
                :name="is_national ? 'CLABE' : 'ABBA / SWIFT / BIC'"
                :vid="is_national ? 'CLABE' : 'ABBA / SWIFT / BIC'"
                rules="required">
                <b-form-input 
                  id="clabe" 
                  v-model="clabe" 
                  :placeholder="is_national ? 'CLABE' : 'ABBA / SWIFT / BIC'" 
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('account')"
              label-for="account"
            >
              <validation-provider  
                #default="{ errors }"
                :name="$t('account')"
                :vid="$t('account')"
                rules="required">
                <b-form-input 
                  id="account" 
                  v-model="account" 
                  :placeholder="$t('account')" 
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('email')"
              label-for="email"
            >
              <validation-provider rules="required|email" name="email" #default="{ errors }">
                <b-form-input id="email" v-model="email_value"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group :label="$t('telephone')" class="avenir-medium f-12">
              <validation-provider
                name="Celular"
              >
                <input-tel-brandme
                  :country_dad="country"
                  :number_dad="telephone"
                  @number="updateTelephone"
                ></input-tel-brandme>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              v-if="!is_national"
              :label="$t('city')"
              label-for="city"
            >
              <validation-provider 
                #default="{ errors }"
                :name="$t('city')"
                :vid="$t('city')"
                rules="required">
                <b-form-input 
                  id="city" 
                  v-model="city"
                  :placeholder="$t('city')" 
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              v-if="!is_national"
              :label="$t('diecctionInternational')"
              label-for="address"
            >
              <validation-provider  
                #default="{ errors }"
                :name="$t('address')"
                :vid="$t('address')"
                rules="required">

                <b-form-input 
                  id="address" 
                  v-model="address"  
                  :placeholder="$t('address')" 
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-1 text-center">
        <b-button
          variant="blue-button"
          class="blue-button"
          :disabled="invalid || !state_telephone"
          @click="updatePyamentData()"
        >
          {{$t('dashboard.save')}}
        </b-button>
      </div>
      </b-form>
    </validation-observer>
      <sider-paypal
      class="open-sidebar-search"
      :payoneer="sider_payoneer"
      @close_siderbar_paypal="closeSider"
      @update_email="updateProfile"
      :show_sider_paypal="show_sider_paypal"
      :profile="profile"
      v-if="show_sider_paypal"/>

  </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormCheckbox, BButton, BFormInput, BBadge, BTooltip, BLink,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { getUserData, updatePaymentData } from '@/libs/utils/user';
import profile_service from "@/services/profile";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import SiderPaypal from '@/views/pages/proposals/stepper/siderPaypal.vue';
import InputTelBrandme from '@/views/components/inputs/InputTelBrandme.vue';

export default {
  name: "PaymentData",
  components: {
    SiderPaypal,
    BTooltip,
    BBadge,
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BFormInput,
    BLink,
    ValidationProvider,
    ValidationObserver,
    InputTelBrandme,
  },
  props: {
    profile: {
      type: Object
    }
  },

  data() {
    return {
      updatePaymentData,
      getUserData,
      sider_payoneer: false,
      show_sider_paypal: false,
      required,
      email,
      is_national: false,
      beneficiary_name: '',
      beneficiary_last_name: '',
      bank: '',
      clabe: '',
      account: '',
      email_value: '',
      telephone: '',
      country: 'MX',
      city: '',
      address: '',
      is_verified: '',
      state_telephone: false,
    };
  },
  created() {
    const user = getUserData()
    if (user.has_payment_data) {
      this.is_national = user.payment_data.is_national
      this.beneficiary_name = user.payment_data.beneficiary_name
      this.beneficiary_last_name = user.payment_data.beneficiary_last_name
      this.bank = user.payment_data.bank
      this.clabe = user.payment_data.clabe
      this.account = user.payment_data.account
      this.email_value = user.payment_data.email
      this.telephone = user.payment_data.telephone
      this.country = user.payment_data.country
      this.city = user.payment_data.city
      this.address = user.payment_data.address
      this.is_verified = user.is_verified
    }
  },
  methods: {
    siderPayoneer() {
      this.sider_payoneer = true
      this.show_sider_paypal = true
    },
    siderPayPal() {
      this.sider_payoneer = false
      this.show_sider_paypal = true
    },
    updateTelephone(telephone_data) {
      this.telephone = telephone_data.number;
      this.state_telephone = telephone_data.state;
      this.country = telephone_data.country;
    },
    updateProfile(email) {
      this.$emit("update_emailPaypalSetting", email);
    },
    closeSider() {
      this.show_sider_paypal = false
    },
    getLabelClabe() {
      return this.is_national ? 'CLABE' : 'ABBA / SWIFT / BIC'
    },
    updatePyamentData() {
      const form_data = {
        is_national: this.is_national,
        beneficiary_name: this.beneficiary_name,
        beneficiary_last_name: this.beneficiary_last_name,
        bank: this.bank,
        clabe: this.clabe,
        account: this.account,
        email: this.email_value,
        telephone: this.telephone,
        country: this.country,
        username: this.$route.params.username,
        network: this.$route.params.network,
      };

      if (!this.is_national) {
        form_data.city = this.city;
        form_data.address = this.address;
      }

      profile_service.updatePaymentData(form_data)
        .then(response => {
          if (response.status) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:  this.$t('err'),
                text: this.$t('messageAccountSettingError'),
                icon: "CoffeeIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('dashboard.saved'),
                variant: 'success',
                icon: 'CheckCircleIcon',
                text: ''
              }
            })
            updatePaymentData(response)
          }
        })
    }
  },
  watch: {
    is_national() {
      this.address = '',
      this.city = ''
    }
  }
};
</script>
<style scoped>
.mr-05 {
  margin-right: 0.5em;
}
.subtitle-current-email {
  font-size: 12px;
  font-family: 'avenir-medium';
}
.link-update-paypal-info {
  color: #0096fe;
}
.f-12 {
  font-size: 12px;
}
</style>
<style>
.card-container {
  border-radius: 0.75rem !important;
}
.border-card-info {
  border: 0.0625rem solid rgba(222,226,230,.7);
  border-top: none;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
}
.border-header {
  border: 0.0625rem solid rgba(222,226,230,.7) !important;
  border-radius: 0.75rem 0.75rem 0rem 0rem !important;
}
.verified-email {
  background: #2f9d76;
  margin-left: 3px;
  font-size: 9.5px;
}
.not-verified-email {
  background: #b41212;
  margin-left: 3px;
  font-size: 9.5px;
}
</style>