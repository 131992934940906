<template>
  <div>
    <b-card v-if="dataTable.length !== 0">
      <b-col>
        <b-table :items="getFormaTable()" id="table-activity" :fields="$t(`table_activity`)" head-variant="light"
          td-class="d-flex" responsive>
          <template #cell(id)="data">
            <span>{{ data.item.id.id }}</span>
          </template>
          
          <template #cell(activity)="data">
            <span>{{ data.item.actitvity }}</span>
          </template>
          
          <template #cell(date)="data">
            <span>{{ data.item.date }}</span>
          </template>
          <no-Results
          v-if="dataTable.length === 0"
          :title="$t('creator.no_results_pitches')"
          :show_button= "false"
        />
        </b-table>
      </b-col>
    </b-card>
    <no-Results
      v-if="dataTable.length === 0"
      :title="$t('creator.no_results_activities')"
      :show_button= "false"
    />
  </div>
</template>

<script>
import { BCard, BCol, BTable } from 'bootstrap-vue'
import service from '@/services/notifications.js';
import utils from '@/libs/utils';
import noResults from '@/views/pages/proposals/stepper/noResults.vue';

export default {
  name: "settingActivity",
  components: {
    noResults,
    BCard,
    BCol,
    BTable,
  },

  data() {
    return {
      utils,
      dataTable: [],
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      service.activityNotification().then(response => {
        this.dataTable = response.results;
      })
    },
    getFormaTable() {
      const data = [];
      this.dataTable.map((item, index) => {
        data.push({
          id: { id: index + 1, uuid: item.uuid },
          actitvity: item.message,
          date: utils.getDatev2(new Date(item.created_at))
        });
      });
      return data;
    }
  },
};
</script>