<template>
  
    <b-card class="w-100">
      <vue-good-table
        :columns="columns"
        :rows="rows"

        :pagination-options="{
          enabled: true,
          perPage: page_length,
        }"
        class="vgt-table-list"
      >
        <template slot="table-column" slot-scope="props">
          <div v-if="props.column.field === 'index'" class="cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'influencer'" class="cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'network'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'followers'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
          </div>
          <div v-if="props.column.field === 'state'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
          </div>
          <span v-if="props.column.field === 'visitDate'" class="d-block text-center">
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'index'" class="text-nowrap row-selected-table ">
            {{ props.row.originalIndex +1 }}
          </span>
          <span v-else-if="props.column.field === 'influencer'" class="text-nowrap d-block text-start"> 
            <b-avatar
              class="mr-1"
              variant="light-primary"
              :src="utils.getImageInfluencer(props.row)"
            />
            <b-link class="link-influencer" :href="getUrlProfile(props.row)" target="_blank">{{ props.row.display_name}}
            <image-verified v-if="props.row.is_verified" class="icon-check"/>
          </b-link> 
          </span>
          <span v-else-if="props.column.field === 'network'" class="text-nowrap d-block text-start"> 
            <b-img v-if="props.row.network.toLowerCase() !== 'twitter'" :src="utils.getImageNetworkColor(props.row.network.toLowerCase())" alt="" class="netIcons mr-1"/>
            <b-avatar v-else class="icon-content-x-visited mr-1">
              <b-img :src="utils.getImageNetworkColor(props.row.network.toLowerCase())" class="icon-networks-x-visited"/>
            </b-avatar>
            <span>{{  utils.capitalize(props.row.network)}}</span> 
          </span>
          <span v-if="props.column.field === 'followers'" class="text-nowrap d-block text-center">
          <span>{{ utils.getFormat(props.row.followers) }}</span>
          </span>
          <span v-else-if="props.column.field === 'state'" class="text-nowrap d-block text-center"> 
            <feather-icon
              icon="CheckIcon"
              size="25"
              class="text-success"
              v-if="props.row"
            />
            <feather-icon
              icon="MinusIcon"
              size="25"
              class="text-secondary"
              v-else
            />
          </span>  
          <span v-else-if="props.column.field === 'visitDate'" class="text-nowrap d-block text-center p-1"> 
            {{ utils.getDatev4(new Date(props.row.created_at))}}
          </span>

        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{$t('campaigns.showing_to')}} {{ page_length }} {{$t('campaigns.of')}} {{ props.total }} {{$t('campaigns.entries')}}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="page_length"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) => props.pageChanged({ currentPage: value })
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

</template>

<script>
import utils from "@/libs/utils";
import { VueGoodTable } from "vue-good-table";
import serviceProfiles from '@/services/others';
import ImageVerified from '@/views/components/image/ImageVerified.vue';

import {
  BLink,
  BAvatar,
  BImg,
  BCard, 
  BPagination,
} from 'bootstrap-vue';

export default {
  name: "AccountSettingProfilesVisited",
  components: {
    ImageVerified,
    BLink,
    BAvatar,
    BImg,
    BCard,
    VueGoodTable,
    BPagination,
  },
  data() {
    return {
      utils,
      columns: [
        {
          label: "#",
          field: "index",
        },
        {
          label: "lists.influencer",
          field: "influencer",
        },
        {
          label: "network",
          field: "network",
        },
        {
          label: "campaigns.followers",
          field: "followers",
          type: "number"
        },
        {
          label: "state",
          field: "state",
        },
        {
          label: "dateVisited",
          field: "visitDate",
        },
      ],    
      rows: [],
      page_length: 20,
    };
  },
  created() {
    serviceProfiles.getVisitedProfiles().then((res) => {
      this.rows = res.results
    })
  },
  methods: {
    getUrlProfile(influencer) {
      if (influencer.network !== 'youtube') return `/perfil/${influencer.network}/${influencer.display_name}/`
      else return `/perfil/${influencer.network}/${influencer.username}/`
    },
  }
};
</script>

<style>
.icon-networks-x-visited {
  width: 1.2em;
}
.icon-content-x-visited {
  background: #000000;
  width: 1.6em;
  height: 1.6em;
}
.link-influencer{
  color: #000000;
}  
.vgt-table-list {
  overflow-x: scroll;
}
.vgt-table-list table {
  width: 100% !important;
}
.container-avatar-table {
  position: relative;
}
.container-avatar-table-icon {
  position: absolute; 
  bottom: -5px; 
  right: -15px;
}
.style-border-icon {
  font-size:1em; 
  border-radius: 50%; 
  background: white; 
  padding: 2px
}
.icon-check {
  height: 18px !important; 
}
.netIcons {
    width: 25px !important;
}
.iconSuccess {
  color: green;
}
</style>